import React, { useEffect, useRef, useState } from 'react';
import http from '../../api/http';
import {
  Button,
  FormGroup,
  FormLabel,
  Link,
  TextField,
  Box,
  FormHelperText,
  Grid,
  Checkbox,
} from '@mui/material';
import { useInfo } from '../../providers/info/info.context';
import AuthLayout from '../../components/AuthLayout/AuthLayout';
import Divider from '@mui/material/Divider';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { marketplaceUrl } from '../../config/config';
import { getApiValidationErrors } from '../../utils';
import GoogleIcon from '../../components/Icons/GoogleIcon';
import FacebookIcon from '../../components/Icons/FacebookIcon';
import { Helmet } from 'react-helmet-async';

const pageTitle = 'CRM - Rejestracja';

const RegisterPage = () => {
  const [formErrors, setFormErrors] = useState<any>({});
  const { showMessage, setLoading } = useInfo();
  const navigate = useNavigate();
  const emailRef = useRef<any>();
  const passwordRef = useRef<any>();
  const nameRef = useRef<any>();
  // const lastNameRef = useRef<any>();
  const termsRef = useRef<any>();
  const [searchParams] = useSearchParams();
  const [title, setTitle] = useState(pageTitle);

  useEffect(() => {
    if (searchParams.get('utm_source')) {
      setTitle(
        `${pageTitle} - ${(searchParams.get('utm_source') || '').toUpperCase()}`
      );
    }
  }, []);

  const onRegister = () => {
    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    const name = nameRef.current.value;
    // const lastName = lastNameRef.current.value;
    const terms = termsRef.current.checked;

    setLoading(true);
    http()
      .post('/auth/register', {
        email,
        password,
        name,
        // lastName,
        terms,
      })
      .then(() => {
        showMessage(
          'Sprawdź skrzynkę pocztową, wysłaliśmy tam wiadomość z linkiem aktywacyjnym',
          'success'
        );
        navigate('/login');
      })
      .catch((errors) => {
        setFormErrors(getApiValidationErrors(errors));
        showMessage(
          'Nie można dokończyć rejestracji, popraw błędy i spróbuj ponownie',
          'error'
        );
      })
      .finally(() => setLoading(false));
  };

  const onClick = (ev: any) => {
    if (ev.keyCode === 13) {
      onRegister();
    }
  };

  return (
    <AuthLayout type={'register'}>
      <>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <h2>Dołącz do TwojTrener.pl</h2>

        <Box sx={{ mb: 3, lineHeight: '150%' }}>
          Na Twój adres e-mail prześlemy wiadomość z linkiem aktywującym konto.
          Po jego kliknięciu zostaniesz automatycznie zalogowany. Rejestracja
          jest bezpłatna.
        </Box>

        <Grid container spacing={2} sx={{ paddingTop: '20px' }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            sx={{ paddingTop: '0 !important' }}
          >
            <FormGroup sx={{ mb: 2 }}>
              <FormLabel>Imię i nazwisko</FormLabel>
              <TextField
                inputRef={nameRef}
                variant={'outlined'}
                type={'text'}
                size={'small'}
                required={true}
                onKeyDown={onClick}
                error={!!formErrors.name}
                inputProps={{ 'data-testid': 'inpName' }}
              />
              {formErrors.name && (
                <FormHelperText error={true}>{formErrors.name}</FormHelperText>
              )}
            </FormGroup>
          </Grid>

          {/*  <Grid item xs={12} sm={12} md={6} sx={{ paddingTop: '0 !important' }}>*/}
          {/*    <FormGroup sx={{ mb: 2 }}>*/}
          {/*      <FormLabel>Nazwisko</FormLabel>*/}
          {/*      <TextField*/}
          {/*        inputRef={lastNameRef}*/}
          {/*        variant={'outlined'}*/}
          {/*        type={'text'}*/}
          {/*        size={'small'}*/}
          {/*        required={true}*/}
          {/*        onKeyDown={onClick}*/}
          {/*        error={!!formErrors.lastName}*/}
          {/*        inputProps={{ 'data-testid': 'inpLastName' }}*/}
          {/*      />*/}
          {/*      {formErrors.lastName && (*/}
          {/*        <FormHelperText error={true}>*/}
          {/*          {formErrors.lastName}*/}
          {/*        </FormHelperText>*/}
          {/*      )}*/}
          {/*    </FormGroup>*/}
          {/*  </Grid>*/}
        </Grid>

        <FormGroup sx={{ mb: 2 }}>
          <FormLabel>Podaj adres e-mail</FormLabel>
          <TextField
            inputRef={emailRef}
            variant={'outlined'}
            type={'email'}
            size={'small'}
            required={true}
            onKeyDown={onClick}
            error={!!formErrors.email}
            inputProps={{ 'data-testid': 'inpEmail' }}
          />
          {formErrors.email && (
            <FormHelperText error={true}>{formErrors.email}</FormHelperText>
          )}
        </FormGroup>

        <FormGroup sx={{ mb: 2 }}>
          <FormLabel>Ustaw hasło</FormLabel>
          <TextField
            inputRef={passwordRef}
            variant={'outlined'}
            size={'small'}
            type={'password'}
            required={true}
            onKeyDown={onClick}
            error={!!formErrors.password}
            inputProps={{ 'data-testid': 'inpPassword' }}
          />
          {formErrors.password && (
            <FormHelperText error={true}>{formErrors.password}</FormHelperText>
          )}
        </FormGroup>

        <Box sx={{ mb: 4 }}>
          <FormLabel
            error={!!formErrors?.terms}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <Checkbox inputRef={termsRef} />
            <span>
              Akceptuję{' '}
              <Link
                href={`${marketplaceUrl}/regulamin`}
                data-testid={'linkTerms'}
                target="_blank"
              >
                Regulamin
              </Link>{' '}
              oraz{' '}
              <Link
                href={`${marketplaceUrl}/polityka-prywatnosci`}
                data-testid={'linkPolicy'}
                target="_blank"
              >
                Politykę prywatności i plików cookies
              </Link>
            </span>
          </FormLabel>
          {formErrors?.terms && (
            <FormHelperText error={true}>{formErrors.terms}</FormHelperText>
          )}
        </Box>

        <div className="text-center mb20">
          <Button
            onClick={onRegister}
            variant={'contained'}
            size={'large'}
            data-testid={'btnRegister'}
          >
            Zarejestruj się
          </Button>
        </div>

        {/*<Divider className="mb20" />*/}

        {/*<div className="text-center mb20">*/}
        {/*  <Button*/}
        {/*    onClick={() => navigate('/login/?type=g')}*/}
        {/*    variant={'outlined'}*/}
        {/*    size={'large'}*/}
        {/*    type={'submit'}*/}
        {/*    data-testid={'btnLogin'}*/}
        {/*    startIcon={<GoogleIcon />}*/}
        {/*  >*/}
        {/*    Kontynuuj z Google*/}
        {/*  </Button>*/}
        {/*</div>*/}

        {/*<div className="text-center mb20">*/}
        {/*  <Button*/}
        {/*    onClick={() => navigate('/login/?type=f')}*/}
        {/*    variant={'outlined'}*/}
        {/*    size={'large'}*/}
        {/*    type={'submit'}*/}
        {/*    data-testid={'btnLogin'}*/}
        {/*    startIcon={<FacebookIcon />}*/}
        {/*  >*/}
        {/*    Kontynuuj z Facebook*/}
        {/*  </Button>*/}
        {/*</div>*/}

        <Divider className="mb20" />

        <div className={'text-center mb20'}>
          Masz już konto?{' '}
          <Button
            variant={'outlined'}
            onClick={() => navigate('/login')}
            data-testid={'linkLogin'}
            size={'small'}
          >
            Zaloguj się
          </Button>
        </div>
      </>
    </AuthLayout>
  );
};
export default RegisterPage;
