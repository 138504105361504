import React, { useEffect, useState } from 'react';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import PhotoIcon from '@mui/icons-material/Photo';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import BarChartIcon from '@mui/icons-material/BarChart';
import SettingsIcon from '@mui/icons-material/Settings';
import SubNavigation from '../../components/SubNavigation/SubNavigation';
import PageHeader from '../../components/PageHeader/PageHeader';
import { Alert, Box, Button } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PlaceIcon from '@mui/icons-material/Place';
import LanguageIcon from '@mui/icons-material/Language';
import { marketplaceUrl } from '../../config/config';

const navigationItems: any[] = [
  {
    label: 'Dane podstawowe',
    icon: <FolderSharedIcon />,
    path: '/profile/data',
  },
  { label: 'Zdjęcie profilowe', icon: <PhotoIcon />, path: '/profile/photo' },
  {
    label: 'Lokalizacje treningów',
    icon: <PlaceIcon />,
    path: '/profile/places',
  },
  { label: 'Galeria', icon: <PhotoLibraryIcon />, path: '/profile/gallery' },
  // { label: 'Ustawienia', icon: <SettingsIcon />, path: '/profile/settings' },
  // {
  //   label: 'Social media i kontakt',
  //   icon: <InstagramIcon />,
  //   path: '/profile/contact',
  // },
  {
    label: 'Statystyki',
    icon: <BarChartIcon />,
    path: '/profile/statistics',
  },
  // {
  //   label: 'Podgląd wizytówki',
  //   icon: <PreviewIcon />,
  //   path: '/profile/preview',
  // },
];

const ProfilePageNavigation = () => {
  const [trainerData, updateTrainerData] = useOutletContext<any>();
  const [isAbleToActivate, setIsAbleToActivate] = useState<boolean>(false);

  useEffect(() => {
    setIsAbleToActivate(
      !!(trainerData.name && trainerData.slug && trainerData.cityId)
    );
  }, [trainerData]);

  return (
    <>
      <PageHeader label={'Wizytówka'} />
      {trainerData && (
        <Box sx={{ padding: '5px 16px' }}>
          <Alert
            color={trainerData.isActive ? 'success' : 'warning'}
            sx={{ mb: '10px' }}
          >
            Profil w serwisie twojtrener.pl jest{' '}
            <strong>{trainerData.isActive ? 'widoczny' : 'ukryty'}</strong>
          </Alert>

          {trainerData.isActive && isAbleToActivate && (
            <>
              <Button
                variant={'contained'}
                size={'small'}
                startIcon={<VisibilityOffIcon />}
                onClick={() => updateTrainerData({ isActive: false })}
              >
                Ukryj profil
              </Button>
              <Button
                variant={'contained'}
                size={'small'}
                startIcon={<LanguageIcon />}
                onClick={() => {
                  window.open(
                    `${marketplaceUrl}/trener/${trainerData.slug}`,
                    '_blank'
                  );
                }}
                sx={{ marginLeft: '10px' }}
              >
                Podgląd wizytówki
              </Button>
            </>
          )}
          {!trainerData.isActive && isAbleToActivate && (
            <Button
              variant={'contained'}
              size={'small'}
              startIcon={<VisibilityIcon />}
              onClick={() => updateTrainerData({ isActive: true })}
            >
              Aktywuj profil
            </Button>
          )}

          {!isAbleToActivate && (
            <strong>
              Aby aktywować profil musisz uzupełnić nazwę oraz miejscowość
            </strong>
          )}
        </Box>
      )}
      <SubNavigation items={navigationItems} />
    </>
  );
};
export default ProfilePageNavigation;
