import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from '../../providers/auth/auth.context';
import {
  Box,
  Button,
  Checkbox,
  FormGroup,
  FormHelperText,
  FormLabel,
  Link,
  TextField,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import AuthLayout from '../../components/AuthLayout/AuthLayout';
import {
  FBClientId,
  FBLoginRedirectUri,
  googleClientId,
  marketplaceUrl,
} from '../../config/config';
import ClientForm, {
  ClientFormData,
} from '../../components/ClientForm/ClientForm';
import { useInfo } from '../../providers/info/info.context';
import http from '../../api/http';
import GoogleLogin, { useGoogleLogin } from '@dump-work/react-google-login';
import GoogleIcon from '../../components/Icons/GoogleIcon';
import FacebookIcon from '../../components/Icons/FacebookIcon';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormError } from '../../types/FormError';
import { apiErrorsAsFormErrors, getApiValidationErrors } from '../../utils';

const LoginPage = () => {
  const [formErrors, setFormErrors] = useState<any>({});
  const [clientFormErrors, setClientFormErrors] = useState<FormError[]>([]);
  const passwordRef = useRef<any>();
  const emailRef = useRef<any>();
  const navigate = useNavigate();
  const { onLogin, handleToken } = useAuth();
  const { setLoading, showMessage } = useInfo();
  const [googleUserData, setGoogleUserData] = useState<ClientFormData>();
  const [googleToken, setGoogleToken] = useState('');
  const [googleEmail, setGoogleEmail] = useState('');
  const termsRef = useRef<any>();
  const { loaded } = useGoogleLogin({ clientId: googleClientId });
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('type') === 'g' && loaded) {
      document?.getElementById('btnGoogleLogin')?.click();
      setSearchParams();
    } else if (searchParams.get('type') === 'f') {
      document?.getElementById('btnFacebookLogin')?.click();
      setSearchParams();
    }
  }, [searchParams, loaded]);

  const onLoginClick = () => {
    const password = passwordRef.current.value;
    const email = emailRef.current.value;
    onLogin(email, password);
  };
  const onFBLoginClick = () => {
    const stateHash = new Date().getTime();
    const fbLoginUrl = `https://www.facebook.com/v16.0/dialog/oauth?client_id=${FBClientId}&redirect_uri=${FBLoginRedirectUri}&state=${stateHash}&scope=email`;
    window.location.replace(fbLoginUrl);
  };

  const googleLoginSuccess = (data: any) => {
    if (data && data.tokenObj) {
      onGoogleLogin(data.tokenObj.id_token);
    }
  };

  const googleLoginFailure = () => {
    showMessage('Nie udało się zalogować, spróbuj ponownie później', 'error');
  };

  const onClick = (ev: any) => {
    if (ev.keyCode === 13) {
      onLoginClick();
    }
  };

  const onGoogleLogin = (idToken: string) => {
    if (!idToken) {
      return false;
    }
    setLoading(true);
    http()
      .post('/auth/login/google', { idToken })
      .then(({ data }) => {
        if (data?.status && data.status === 'ok' && data?.token) {
          showMessage('Zalogowano poprawnie', 'success');
          handleToken(data.token, '/');
        } else {
          setGoogleUserData(data);
          setGoogleEmail(data.email);
          setGoogleToken(idToken);
        }
      })
      .catch(() => {
        showMessage(
          'Wystąpił błąd komunikacji z serwisem Google, spróbuj ponownie za chwilę',
          'error'
        );
      })
      .finally(() => setLoading(false));
  };

  const onGoogleRegisterClick = () => {
    const terms = termsRef.current.checked;
    setLoading(true);
    http()
      .post('/auth/register/google', {
        ...googleUserData,
        email: googleEmail,
        terms,
        idToken: googleToken,
      })
      .then(({ data }) => {
        if (data?.token) {
          showMessage('Zalogowano poprawnie', 'success');
          handleToken(data.token);
        } else {
          showMessage('Błąd logowania, spróbuj ponownie za chwilę', 'error');
        }
      })
      .catch((errors) => {
        setFormErrors(getApiValidationErrors(errors));
        setClientFormErrors(apiErrorsAsFormErrors(errors));
        if (errors?.response?.data?.status === 'err') {
          showMessage('Popraw błędy w formularzu i spróbuj ponownie', 'error');
        } else {
          showMessage(
            'Wystąpił błąd komunikacji z serwisem Google, spróbuj ponownie za chwilę',
            'error'
          );
        }
      })
      .finally(() => setLoading(false));
  };

  const onChange = (data: ClientFormData) => setGoogleUserData(data);

  return (
    <AuthLayout>
      <>
        {googleToken && (
          <>
            <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>
              Zarejestruj się kontem Google
            </h2>
            <div className="mb20">
              {googleUserData && (
                <ClientForm
                  validationTrigger={1}
                  formErrors={clientFormErrors}
                  onChange={onChange}
                  defaultData={googleUserData}
                  isEmailDisabled={true}
                />
              )}
            </div>
            <Box sx={{ mb: 4 }}>
              <FormLabel
                error={!!formErrors?.terms}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <Checkbox inputRef={termsRef} />
                <span>
                  Akceptuję{' '}
                  <Link
                    href={`${marketplaceUrl}/regulamin`}
                    data-testid={'linkTerms'}
                    target="_blank"
                  >
                    Regulamin
                  </Link>{' '}
                  oraz{' '}
                  <Link
                    href={`${marketplaceUrl}/polityka-prywatnosci`}
                    data-testid={'linkPolicy'}
                    target="_blank"
                  >
                    Politykę prywatności i plików cookies
                  </Link>
                </span>
              </FormLabel>
              {formErrors?.terms && (
                <FormHelperText error={true}>{formErrors.terms}</FormHelperText>
              )}
            </Box>
            <div className="text-center mb20">
              <Button
                onClick={onGoogleRegisterClick}
                variant={'contained'}
                size={'large'}
                type={'submit'}
                data-testid={'btnLogin'}
                className="btn-r"
              >
                Kontynuuj z Google
              </Button>
            </div>
          </>
        )}

        {!googleToken && (
          <>
            <h2>Zaloguj się jako trener</h2>
            <FormGroup sx={{ mb: 2 }}>
              <FormLabel>Adres e-mail</FormLabel>
              <TextField
                inputRef={emailRef}
                variant={'outlined'}
                type={'email'}
                size={'small'}
                required={true}
                onKeyDown={onClick}
                inputProps={{ 'data-testid': 'inpEmail' }}
              />
            </FormGroup>
            <FormGroup sx={{ mb: 4 }}>
              <FormLabel>Hasło</FormLabel>
              <TextField
                inputRef={passwordRef}
                variant={'outlined'}
                type={'password'}
                size={'small'}
                required={true}
                onKeyDown={onClick}
                inputProps={{ 'data-testid': 'inpPassword' }}
              />
            </FormGroup>
            <div className="text-center mb20">
              <Button
                onClick={onLoginClick}
                variant={'contained'}
                size={'large'}
                type={'submit'}
                data-testid={'btnLogin'}
              >
                Zaloguj się
              </Button>
            </div>

            <div className="text-center mb20">
              <Link href={'/reset-password'} data-testid={'linkResetPassword'}>
                Zapomniałem hasła
              </Link>
            </div>

            {/*<Divider sx={{ mb: '20px' }} />*/}

            {/*<div className="text-center mb20">*/}
            {/*  <GoogleLogin*/}
            {/*    clientId={googleClientId}*/}
            {/*    render={(renderProps) => (*/}
            {/*      <Button*/}
            {/*        id="btnGoogleLogin"*/}
            {/*        onClick={renderProps.onClick}*/}
            {/*        disabled={renderProps.disabled}*/}
            {/*        variant={'outlined'}*/}
            {/*        size={'large'}*/}
            {/*        type={'submit'}*/}
            {/*        data-testid={'btnLogin'}*/}
            {/*        startIcon={<GoogleIcon />}*/}
            {/*      >*/}
            {/*        Kontynuuj z Google*/}
            {/*      </Button>*/}
            {/*    )}*/}
            {/*    onSuccess={googleLoginSuccess}*/}
            {/*    onFailure={googleLoginFailure}*/}
            {/*    cookiePolicy={'single_host_origin'}*/}
            {/*  />*/}
            {/*</div>*/}

            {/*<div className={'text-center'}>*/}
            {/*  <Button*/}
            {/*    id="btnFacebookLogin"*/}
            {/*    onClick={() => onFBLoginClick()}*/}
            {/*    variant={'outlined'}*/}
            {/*    size={'large'}*/}
            {/*    type={'submit'}*/}
            {/*    data-testid={'btnLogin'}*/}
            {/*    startIcon={<FacebookIcon />}*/}
            {/*  >*/}
            {/*    Kontynuuj z Facebook*/}
            {/*  </Button>*/}
            {/*</div>*/}

            <Divider sx={{ mt: '20px', mb: '20px' }} />

            <div className={'text-center'}>
              Nie masz konta?{' '}
              <Button
                variant={'outlined'}
                onClick={() => navigate('/register')}
                data-testid={'linkRegister'}
                size={'small'}
              >
                Zarejestruj się
              </Button>
            </div>
          </>
        )}
      </>
    </AuthLayout>
  );
};
export default LoginPage;
